import React, { Component } from "react";
import { Link } from "gatsby";
import Tags from "./tag";
import { GatsbyImage } from "gatsby-plugin-image";

export default (props) => (
  <React.Fragment>
    <article className={`post-card no-image post-card-tag`}>
      <ContentNoImage props={props} />
      {/* <ContentWithImage props={props} /> */}
    </article>
  </React.Fragment>
);

class ContentNoImage extends Component {
  render() {
    const { props } = this.props;
    return (
      <div className="post-card-content">
        <div>
          <Link to={props.node.fields.slug} className="post-card-link">
            <h2 className="post-card-title">
              {props.node.frontmatter.title || props.node.fields.slug}
            </h2>
          </Link>
        </div>
        <div className="post-card-date">{props.node.frontmatter.date.slice(-4)}</div>
        <div className="post-card-body">
          {props.node.frontmatter.description || props.node.excerpt}
        </div>
        <div>
          <Link to={props.node.fields.slug} className="post-card-link post-card-readmore">
            {props.node.frontmatter.description || props.node.excerpt ? "Read more" : null}
          </Link>
        </div>
        <div>
          <Tags tags={props.node.frontmatter.tags} />
        </div>
      </div>
    );
  }
}

class ContentWithImage extends Component {
  render() {
    const { props } = this.props;
    return (
      <Link to={props.node.fields.slug} className="post-card-link">
        <GatsbyImage
          image={props.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
          alt="imagen representativa del proyecto"
          className="post-card-image-content"
        />
        <div className="post-card-content">
          <h2 className="post-card-title">
            {props.node.frontmatter.title || props.node.fields.slug}
          </h2>
        </div>
      </Link>
    );
  }
}
